<template>
  <div v-if="visible">
    <el-dialog
      title="添加检测项"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form
        ref="formRef"
        :model="formData"
        :rules="formRule"
        label-width="110px"
      >
        <el-form-item label="检测项编码：" prop="failureCode">
          <el-input
            v-model.trim="formData.failureCode"
            maxlength="8"
            :disabled="isCompile"
            placeholder="请输入编码，最多可设置8位数字或字母"
            type="text"
            size="small"
          />
        </el-form-item>
        <el-form-item label="检测标题：" prop="failureName">
          <el-input
            v-model.trim="formData.failureName"
            maxlength="20"
            placeholder="请输入标题名称，最多20个字"
            type="text"
            size="small"
          />
        </el-form-item>
        <el-form-item label="检测提示：" prop="failurePrompt">
          <el-input
            v-model.trim="formData.failurePrompt"
            type="textarea"
            autosize
            placeholder="请输入提示文字，最多100字"
            size="small"
          />
        </el-form-item>
        <el-form-item label="示例图：" prop="failureImgList">
          <!-- <ElImgUpload
            :isBigImg="true"
            :exist-image="formData.sampleImage"
            placeTxt="提示：图片仅支持上传jpg/png/gif格式，建议尺寸：340-340px"
            @handleChange="sampleHandleChange"
          /> -->
          <ElImgUploadGroup
            place-txt="提示：图片仅支持上传jpg/png/gif格式，建议尺寸：340-340px,最多上传3张。"
            :length="3"
            :exist-img-list="formData.failureImgList"
            @handleChange="handleUploadGroupChange($event, item.key)"
          />
        </el-form-item>
        <div style="margin-left: 20px">
          <p class="self_detion"><span>*</span>检测选项：</p>
          <el-form-item label="正常项：" prop="optionNameNormal">
            <el-input
              v-model.trim="formData.optionNameNormal"
              maxlength="8"
              placeholder="请输入正常项,最多8个字"
              type="text"
              size="small"
            />
          </el-form-item>
          <el-form-item label="异常项：" prop="optionNameError">
            <el-input
              v-model.trim="formData.optionNameError"
              maxlength="8"
              placeholder="请输入异常项,最多8个字"
              type="text"
              size="small"
            />
          </el-form-item>
        </div>
        <el-form-item
          v-if="type == '01'"
          label-width="210px"
          label="选择正常是否需要上传凭证："
          prop="normalIsUpVoucher"
        >
          <el-radio-group v-model="formData.normalIsUpVoucher">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="visible = false">取消</el-button>
        <el-button type="primary" @click.native="confirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import _api from "@/utils/request";
// import ElImgUpload from "@/components/global/components/elements/upload-element";
import ElImgUploadGroup from "@/components/global/components/elements/upload-group-element";
const originFormData = {
  failureCode: "", //检测项编码
  failureImgList: [], //示例图
  failureName: "", //检测标题
  failurePrompt: "", //检测提示
  normalIsUpVoucher: true, //正常是否需要上传凭证 false否 true是
  optionNameError: "", //检测选项-异常项
  optionNameNormal: "", //检测选项-正常项
};
export default {
  name: "AddSampleImageDia",
  components: {
    //  ElImgUpload ,
    ElImgUploadGroup,
  },
  props: {
    position: [String, Number],
    machineTypeId: [String, Number],
    groupId: [String, Number],
  },
  data() {
    return {
      isCompile:false,
      visible: false,
      type: "", // 00示例图 01补充细节图
      formData: originFormData,
      formRule: {
        failureCode: [
          { required: true, message: "请输入检测项编码", trigger: "change" },
        ],
        failureName: [
          { required: true, message: "请输入检测标题", trigger: "change" },
        ],
        failurePrompt: [
          { required: true, message: "请输入检测提示", trigger: "change" },
        ],
        optionNameError: [
          {
            required: true,
            message: "请输入检测选项异常项",
            trigger: "change",
          },
        ],
        optionNameNormal: [
          {
            required: true,
            message: "请输入检测选项正常项",
            trigger: "change",
          },
        ],
        normalIsUpVoucher: [
          { required: true, message: "请选择是否必填", trigger: "change" },
        ],
        failureImgList: [
          { required: true, message: "请上传图片", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    open(type, row) {
      this.type = type;
      if (row) {
        console.log(row);
        this.isCompile=true
        _api.getonlyhidecingfiginfo({id:row.id}).then(res=>{
          let alldata=res.data
          this.formData = {
          id: alldata.id,
          failureCode: alldata.failureCode,
          failureImgList:alldata.failureImgList|| [],
          failureName: alldata.failureName,
          failurePrompt: alldata.failurePrompt,
          normalIsUpVoucher: alldata.normalIsUpVoucher,
          optionNameError:alldata.optionNameError,
          optionNameNormal: alldata.optionNameNormal,
        };
        })
      } else {
        this.isCompile=false
        this.formData = JSON.parse(JSON.stringify(originFormData));
      }
      this.$refs["formRef"]?.clearValidate();
      this.$nextTick(() => {
        this.visible = true;
      });
    },
    close() {
      this.visible = false;
    },
    handleUploadGroupChange(list) {
      console.log(list);
      this.formData.failureImgList = list;
    },
    sampleHandleChange(v) {
      this.formData.sampleImage = v;
    },
    confirm() {
      this.$refs["formRef"].validate((vaild) => {
        if (vaild) {
          _api
            .getIDfaultsave({
              ...this.formData,
              groupId: this.groupId,
            })
            .then(() => {
              this.$emit("confirm", this.type);
              this.$message.success("操作成功！");
              this.close();
            });
        }
      });
    },
  },
};
</script>
  <style lang="scss" scoped ref="stylesheet/scss">
.self_detion {
  font-size: 14px;
  color: #666666;
  span {
    color: #ff687b;
  }
}
</style>