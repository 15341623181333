<template>
  <div class="caseFailure-caseImg">
    <GlobalInfoBar
      title="隐藏ID检测管理"
      content="设置隐藏ID机型、配置管理隐藏ID选项"
    />
    <div class="case">
      <div class="case-title">
        <div class="case-title_max">隐藏ID检测项管理</div>
        <div class="case-title_max" style="font-size: 14px">
          <span>了解什么是隐藏ID机</span>
          <span
            style="margin-left: 20px"
            class="blu_text"
            @click="seeALLmerchant"
            >设置海报</span
          >
        </div>
      </div>
      <div style="padding: 0 20px">
        <div class="tab-box">
          <el-tabs
            v-model="groupId"
            editable
            @tab-click="groupChange"
            @tab-remove="removeTab"
          >
            <el-tab-pane
              v-for="item in personalConfigList"
              :key="item.id"
              :label="item.name"
              :name="item.id + ''"
            />
          </el-tabs>
          <el-link
            type="warning"
            :underline="false"
            class="add-btn"
            @click="$refs['addPersonalConfigDia'].open()"
            >+添加个性配置</el-link
          >
        </div>
      </div>
      <div v-if="groupId" style="padding: 0 20px">
        <div class="add-spesial">
          <div class="title-base">
            设置机型
            <div class="model-num-box">
              已设置{{ sampleImageSpecialData.machineNum }}个机型
              <span class="link primary" @click="manageModel">管理机型</span>
            </div>
          </div>
          <div>
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="$refs['addSampleImageDia'].open('01')"
              >+添加检测项</el-button
            >
          </div>
        </div>
        <GlobalTable
          :maxHeight="800"
          class="sample-image"
          ref="GlobalTable"
          v-loading="tableLoading2"
          :columns="tableColumns2"
          :data="sampleImageSpecialData.faultList"
          :isPagination="false"
        >
          <!-- <el-table-column label="示例图" slot="sampleImage" align="center">
            <template slot-scope="{ row }">
              <el-image
                style="width: 70px; height: 70px"
                :src="row.sampleImage"
                :preview-src-list="[row.sampleImage]"
              >
              </el-image>
            </template>
          </el-table-column> -->
          <el-table-column label="检测提示" slot="failurePrompt" align="center">
            <template slot-scope="{ row }">
              {{ row.failurePrompt }}
            </template>
          </el-table-column>
          <el-table-column label="启用" slot="isEnable" align="center">
            <template slot-scope="{ row }">
              <el-switch
                v-model="row.isEnable"
                @change="(e) => switchChange(e, row, '01', true)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="排序" slot="sort" align="center">
            <template slot-scope="{ row }">
              <el-input-number
                v-model="row.sort"
                @change="handleChange(row, true)"
                size="mini"
                :min="1"
                label="排序"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="操作" slot="operation" align="center">
            <template slot-scope="{ row }">
              <el-button
                size="mini"
                type="primary"
                round
                @click="$refs['addSampleImageDia'].open('01', row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
    </div>
    <GlobalDialog
      :title="dialogTitle"
      :show="dialogVisible"
      :width="dialogWidth"
      @resetPopupData="resetPopupData"
      @submitPopupData="submitPopupData"
      @onClose="onClose"
    >
      <div style="font-size: 16px; color: #666666">
        删除示例图，将影响门店端显示，确认要执行操作吗？
      </div>
    </GlobalDialog>
    <!--弹框-->
    <el-dialog
      class="set-img"
      title="设置海报"
      :visible.sync="imageDialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="imageHandleClose"
    >
      <div>
        <div style="margin-bottom: 10px; font-size: 14px; color: #333333">
          隐形ID海报
        </div>
        <div class="demo-image__preview">
          <ElImgUpload
            key="posterImage"
            :isBigImg="true"
            :exist-image="posterImage"
            @handleChange="sampleHandleChange"
          />
          <p style="color: #ff687b; font-size: 12px">
            (提示：图片仅支持上传jpg/png/gif格式，建议尺寸：340-340px)
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="imageDialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click.native="imageHandleSure"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 上下架 -->
    <el-dialog
      :title="putDialogTitle"
      :visible.sync="putDialogShow"
      :close-on-click-modal="false"
      width="550px"
      @closed="putDialogClosed"
    >
      <div class="cance-tip">
        <div class="tip-sure">
          是否确认{{ putDialogTitle == "上架提示" ? "上架" : "下架" }}该检测项
          ？
        </div>
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="putDialogShow = false">取消</el-button>
        <el-button
          type="primary"
          :loading="putBtnLoading"
          @click.native="putDialogSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 删除个性配置 -->
    <el-dialog
      title="删除提示"
      :visible.sync="detaleShow"
      :close-on-click-modal="false"
      width="550px"
      @closed="detaleDialogClosed"
    >
      <div class="cance-tip">
        <div class="tip-sure">是否确认删除{{ detalegroupdata.name }}的配置数据？</div>
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="detaleShow = false">取消</el-button>
        <el-button
          type="primary"
          :loading="putBtnLoading"
          @click.native="detaleDialogSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-image-viewer
      v-if="showViewer"
      :on-close="(showViewer = false)"
      :url-list="imgList"
    />
    <ModelConfigDia ref="modelConfigDia" @confirm="modelConfigNext" />
    <!-- 选择机型 -->
    <ModelSelectDia
      ref="specialModelSelectDia"
      :ids="machineIdList"
      :machineTypeId="calssSelect"
      :position="position"
      filter="00"
      @confirm="specialModelSelectConfirm"
    />
    <AdddispositionDia
      ref="addPersonalConfigDia"
      :position="position"
      :machineTypeId="calssSelect"
      @confirm="getPersonalConfig()"
    />
    <detectiondialog
      ref="addSampleImageDia"
      :position="position"
      :machineTypeId="calssSelect"
      :groupId="groupId"
      @confirm="addSampleConfirm"
    />
  </div>
</template>
  
  <script>
import _api from "@/utils/request";
import environments from "@/config/url";
import { encryptByDES } from "@/utils/3DES";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import ElImgUpload from "../../components/global/components/elements/upload-element";
import ModelConfigDia from "./dialog/ModelConfigDia";
import ModelSelectDia from "./dialog/idmodelselectDia.vue";
import AdddispositionDia from "./dialog/AdddispositionDia.vue";
import detectiondialog from "./dialog/detectiondialog.vue";

export default {
  name: "oldBrand-list",
  components: {
    ElImageViewer,
    ElImgUpload,
    ModelConfigDia,
    ModelSelectDia,
    AdddispositionDia,
    detectiondialog,
  },
  data() {
    return {
      detalegroupdata:{
      },
      // 补充示例图数据
      isSpecial: false, //标记
      tableColumns2: [
        { label: "编码", prop: "failureCode" },
        { label: "检测项标题", prop: "failureName" },
        { slotName: "sampleImage" },
        { slotName: "isMust" },
        { slotName: "isEnable" },
        { slotName: "sort" },
        { slotName: "operation" },
      ],
      tableLoading2: false,
      uploadUrl: environments[process.env.VUE_APP_BASE_URL].uploadBaseUrl,
      imageUrl: "",
      headers: {
        ACCESS_TOKEN: localStorage.getItem("token"),
        channel: "platformPc",
        TOKEN: "",
      },
      position: "02",
      dialogVisible: false,
      dialogTitle: "",
      dialogWidth: 380,
      deletedId: "",
      arrAisi: [],
      calssSelect: 26,
      classList: [],
      isAisi: true,
      searchValue: "",
      command: "",
      rowId: "",
      imageDialogTitle: "",
      putDialogTitle: "",
      putDialogShow: false,
      detaleShow: false,
      imageDialogVisible: false,
      tableLoading: false,
      btnLoading: false,
      putBtnLoading: false,
      tableData: [],
      tableColumns: [
        { label: "示例图名称", prop: "imageName" },
        { slotName: "sampleImage" },
        { slotName: "startingUpStow" },
        { slotName: "noStartingUpStow" },
        { slotName: "fireNew" }, //全新机
        { slotName: "elderlyMachineStow" },
        { slotName: "sort" },
        { slotName: "machineConfig" },
        { slotName: "operation" },
      ],
      imgList: [],
      showViewer: false,
      posterImage:"",
      sampleImage: "",
      imageName: "",
      isMust: "",

      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "01",
          label: "可以开机",
        },
        {
          value: "02",
          label: "不能开机",
        },
        {
          value: "03",
          label: "功能机/老年机",
        },
        {
          value: "04",
          label: "全新机",
        },
      ],
      // 配置机型
      modelConfigFormData: {},
      machineIdList: [],
      groupId: undefined,
      personalConfigList: [],
      sampleImageSpecialData: {
        faultList: [],
        machineIds: [],
        machineNum: 0,
      },
    };
  },
  created() {
    this.initData();
  },
  computed: {},
  watch: {
    // 个性配置切换
    groupId() {
      this.getgroupgetInfo();
    },
  },
  methods: {
    async initData() {
      // 获取个性配置列表
      await this.getPersonalConfig();
    },
    // 排序
    handleChange(row, isSpecial) {
      console.log(row);
      const params = {
        id: row.id,
        sort: row.sort,
      };
      _api.hideIdupdateSort(params).then((res) => {
        if (res.code === 1) {
          this.getgroupgetInfo();
          this.$message.success(res.msg);
        }
      });
    },
    //删除
    handleDelete(val) {
      console.log(val);
      this.dialogTitle = "删除提示";
      this.dialogVisible = true;
      this.deletedId = val.id;
    },
    //弹窗确定
    submitPopupData() {
      _api.imageDelete({ id: this.deletedId }).then((res) => {
        if (res.code === 1) {
          this.$message.success("删除成功");
          this.getListByMachineType();
        }
      });
      this.dialogVisible = false;
    },
    //弹窗取消
    resetPopupData() {
      this.dialogVisible = false;
    },
    onClose() {
      this.dialogVisible = false;
    },
    // 成功调用
    handleAvatarSuccessAisi(res) {
      this.imageUrl = res.fileUrl;
      console.log(this.imageUrl);
      const dto = {
        position: "03", //03爱思助手
        sampleImage: this.imageUrl,
        machineTypeId: this.calssSelect,
      };
      _api.ImageAdd(dto).then((res) => {
        if (res.code === 1) {
          this.$message.success(res.msg);
          this.getListByMachineType();
        }
      });
    },
    // 上传前调用
    beforeAvatarUpload(file) {
      this.headers.TOKEN = encryptByDES(
        encryptByDES(
          new Date().getTime().toString(),
          "63e42c2444e94c1ebca21d30d2aa39a5"
        ),
        localStorage.getItem("token") || ""
      );
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG或者PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    groupChange(e) {
      this.groupId = e.name;
    },
    removeTab(e) {
      console.log(e, this.personalConfigList);
      this.detalegroupdata=this.personalConfigList.filter(item=>item.id==e)[0]
      console.log(this.detalegroupdata);
      this.detaleShow = true;
    },
    addSampleConfirm(type) {
      this.getgroupgetInfo();
    },
    manageModel() {
      this.machineIdList = JSON.parse(
        JSON.stringify(this.sampleImageSpecialData.machineIds)
      );
      this.position=''
      this.$nextTick(() => {
        this.$refs["specialModelSelectDia"].open();
      });
    },

    // 获取分类集合
    async getClassList() {
      const res = await _api.selectList();
      const list = res.data || [];
      this.classList = res.data || [];
      if (list.length) {
        this.calssSelect = list[0].id;
      }
    },
    // 获取示例图列表
    getListByMachineType() {
      let params = {
        machineTypeId: this.calssSelect,
        position: this.position,
      };
      if (this.searchValue) {
        switch (this.searchValue) {
          case "01":
            params.startingUpStow = true;
            break;
          case "02":
            params.noStartingUpStow = true;
            break;
          case "03":
            params.elderlyMachineStow = true;
            break;
          case "04":
            params.fireNew = true;
            break;
          default:
            break;
        }
      }
      this.tableLoading = true;
      _api.sampleImage(params).then((res) => {
        if (res.code == 1) {
          console.log(res);
          this.tableData = res.data.sampleImages;
          this.arrAisi = res.data.elsieImages;
          this.tableLoading = false;
        }
      });
    },
    // 获取个性配置列表
    async getPersonalConfig() {
      const res = await _api.getgroupgetSelectListConfig();
      this.personalConfigList = res.data;
      if (this.personalConfigList.length) {
        this.groupId = this.personalConfigList[0].id + "";
      } else {
        this.groupId = "";
      }
    },
    //个性配置-详情
    getgroupgetInfo() {
      if (!this.groupId) return;
      let params = {
        groupId: this.groupId,
      };
      this.tableLoading2 = true;
      _api.getgroupgetInfo(params).then((res) => {
        this.sampleImageSpecialData = res.data;
        this.tableLoading2 = false;
      });
    },
    searchValueChange() {
      this.getListByMachineType();
    },
    // 查看示例图大图
    seeSampleImage(row) {
      this.imgList = [];
      this.showViewer = true;
      this.imgList.push(row.sampleImage);
    },
    // 设置示例图显示配置
    switchChange(e, row, type, isSpecial) {
      this.putDialogTitle = e ? "上架提示" : "下架提示";
      this.rowId = row.id;
      this.isEnable = e;
      this.putDialogShow = true;
      this.putBtnLoading = false;
    },
    // 设置示例图弹框关闭
    imageHandleClose() {
      this.posterImage = "";
      this.imageDialogVisible = false;
    },
    // 圖片更換
    sampleHandleChange(e) {
      this.posterImage = e;
    },
    // 设置海报
    seeALLmerchant() {
      _api.getposterInfo().then(res=>{
        this.imageDialogVisible = true;
        this.posterImage = res.data;
      })
    },
    // 海报图保存确定
    imageHandleSure() {
      if (!this.posterImage) {
        this.$message.error("海报图片不能为空");
        return;
      }
      this.btnLoading = true;
        _api
          .saveposterIDInfo({ posterUrl:this.posterImage })
          .then((res) => {
            if (res.code === 1) {
              this.$message.success(res.msg || "操作成功");
              this.imageDialogVisible = false;
            }
            this.btnLoading = false;
          })
          .catch(() => {
            this.btnLoading = false;
          });
    },
    // 添加/编辑示例图[补充示例图]
    setSampleImageSpecial(type, row) {
      this.isSpecial = true;
      this.imageDialogVisible = true;
      this.rowId = "";
      if (type == "add") {
        this.imageDialogTitle = "添加示例图";
      } else {
        this.rowId = row.id;
        this.imageDialogTitle = "编辑示例图";
        this.sampleImage = row.sampleImage;
        this.imageName = row.imageName;
      }
    },
    // 上/下架弹框关闭回调
    putDialogClosed() {
      this.command = "";
      this.getgroupgetInfo();
    },
    detaleDialogClosed() {
      this.command = "";
    },
    // 删除弹框确定
    detaleDialogSubmit() {
      this.putBtnLoading = true;
      _api
        .removefailurecingfig({
        command: this.command,
        groupId: this.detalegroupdata.id,
      })
        .then((res) => {
          this.$message.success(res.msg || "操作成功");
          this.putBtnLoading = false;
          this.detaleShow=false
          this.getPersonalConfig()
        })
        .catch(() => {
          this.putBtnLoading = false;
        });
    },
    // 上/下架弹框确定
    putDialogSubmit() {
      this.putBtnLoading = true;
      _api
        .hideIdupdateIsEnablecingfig({
          id:this.rowId,
          isEnable: this.isEnable,
          command: this.command,
        })
        .then((res) => {
          this.$message.success(res.msg || "操作成功");
          this.putDialogShow = false;
        })
        .catch(() => {
          this.putBtnLoading = false;
        });
    },
    modelConfigNext({ id, machineConfig }) {
      this.modelConfigFormData = { id, machineConfig };
      if (machineConfig == "00") {
        this.modelSelectConfirm();
      } else {
        _api.getMachineConfig({ id }).then((res) => {
          this.machineIdList = res.data.machineIdList;
          this.$nextTick(() => {
            this.$refs["modelSelectDia"].open();
          });
        });
      }
    },
    modelSelectConfirm(machineIdList) {
      _api
        .saveMachineConfig({
          ...this.modelConfigFormData,
          isIos: this.position == "02" ? "01" : "02",
          machineIdList,
        })
        .then((res) => {
          this.$message.success("修改成功");
          this.getListByMachineType();
          this.$refs["modelSelectDia"].close();
        });
    },
    specialModelSelectConfirm(machineIdList) {
      _api
        .savemachineID({
          groupId: this.groupId,
          machineIds:machineIdList,
        })
        .then((res) => {
          this.$message.success("修改成功");
          this.getgroupgetInfo();
          this.$refs["specialModelSelectDia"].close();
        });
    },
  },
};
</script>
  
  <style scoped lang="scss" ref="stylesheet/scss">
.add-spesial {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title-base {
  font-weight: 700;
  font-size: 16px;
  border-left: 8px solid #3399ff;
  padding: 0 10px;
  box-sizing: border-box;
  color: #000;
  display: flex;
  .model-num-box {
    margin-left: 20px;
    font-size: 14px;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.flex-jb {
  display: flex;
  justify-content: space-between;
}

.flex1 {
  flex: 1;
}

.caseFailure-caseImg {
  /deep/.el-input__inner {
    padding: 0 8px;
  }
}

.button_top {
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.faultBtn {
  width: 250px;
  height: 40px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 21px;
  display: flex;
  cursor: pointer;

  .active {
    text-align: center;
    color: white;
    width: 50%;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 38px;
    height: 38px;
    background: #0981ff;
    border-radius: 20px;
  }

  .none {
    text-align: center;
    color: #333;
    width: 50%;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 38px;
    height: 38px;
    border-radius: 20px;
  }
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cance-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}

.case {
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  width: 100%;
  padding: 14px 0;

  .class_type {
    padding: 20px;

    .tip {
      font-size: 14px;
      color: #666666;
    }

    /deep/.el-radio-group {
      .el-radio-button {
        margin-right: 20px;

        .el-radio-button__inner {
          border-radius: 4px;
          border: 1px solid #0981ff;
        }
      }
    }
  }

  .case-title {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .case-title_icon {
      width: 20px;
      height: 20px;
      margin-right: 3px;
    }

    .case-title_max {
      // width: 150px;
      // height: 17px;
      font-size: 18px;
      font-family: FZLanTingHei-B-GBK;
      font-weight: 400;
      color: #333333;
      line-height: 41px;
      margin-right: 10px;
    }

    .case-title_min {
      margin-left: 5px;
      width: 500px;
      height: 13px;
      font-size: 12px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #ff687b;
      line-height: 45px;
    }
  }

  .case-img {
    margin: 20px;
    height: 280px;
    background: #f5f6fa;
    border-radius: 10px;
    display: flex;
    align-items: center;

    .upload_Add {
      width: 200px;

      // height: 200px;
      /deep/.el-upload--picture-card {
        margin: 0 30px;
      }
    }

    .upload_Class {
      position: relative;
      width: 200px;

      // height: 200px;
      .img:hover .mark {
        position: absolute;
        top: 0;
        left: 0;
        width: 148px;
        height: 148px;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }

      .img {
        position: relative;
        padding: 0;
        width: 148px;
        height: 148px;
        border-radius: 12px;
        margin: 0 auto;

        img {
          width: 148px;
          height: 148px;
        }

        .mark {
          display: none;
        }
      }

      .sort {
        width: 130px;
        height: 30px;
        margin: 10px auto;
      }

      .imageName {
        width: 130px;
        height: 30px;
        margin: 10px auto;
      }
    }
  }

  .sample-image {
    /deep/.el-switch__label * {
      line-height: 1;
      font-size: 12px;
      display: inline-block;
    }

    /deep/.el-switch__label {
      position: absolute;
      display: none;
      color: #fff !important;
      font-size: 12px !important;
    }

    /*打开时文字位置设置*/
    /deep/.el-switch__label--right {
      z-index: 1;
      right: 4px;
    }

    /*关闭时文字位置设置*/
    /deep/.el-switch__label--left {
      z-index: 1;
      left: 4px;
    }

    /*显示文字*/
    /deep/.el-switch__label.is-active {
      display: block;
    }

    /*开关宽度*/
    /deep/.el-switch .el-switch__core,
    /deep/.el-switch .el-switch__label {
     // width: 50px !important;
    }
  }
}
.tab-box {
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  .add-btn {
    margin-bottom: 14px;
    margin-left: 20px;
  }
  /deep/.el-tabs__nav-wrap::after {
    display: none !important;
  }
}

.link {
  text-decoration: underline;
  cursor: pointer;
  &.primary {
    color: #0981ff;
  }
  &.danger {
    color: #ff687b;
  }
}
.blu_text {
  color: #0981ff;
  cursor: pointer;
  text-decoration: underline;
}
/deep/.el-tabs__new-tab {
  display: none;
}
</style>
  